import { Breadcrumbs, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Privacy = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <div role="presentation" style={{ width: "90%", margin: "1% auto 0 auto" }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="text.primary">Privacy Policy</Typography>
                </Breadcrumbs>
            </div>
            <div style={{ width: "80%", margin: "3% auto 0 auto" }}>
                <div>
                    <span className={isSmallScreen ? 'only-heading-sm' : 'only-heading'}>Privacy </span>
                    <span className={isSmallScreen ? 'gradient-heading-sm' : 'gradient-heading'}>Policy</span>
                </div>
                <br></br>
                <div >
                    <h3>Introduction</h3>
                    <p>At PaperLive, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and share information when you visit our website or use our services. By accessing PaperLive's website or services, you agree to the terms outlined in this policy.</p>
                    <br></br>

                    <h3>1. Information We Collect</h3>
                    <p>We may collect the following types of personal information:</p>
                    <ul>
                        <li><strong>Personal Identification Information:</strong> Name, email address, phone number, mailing address, and any other contact details you provide when registering for our courses or services.</li>
                        <li><strong>Payment Information:</strong> Credit/debit card details, billing address, and payment history when you enroll in courses.</li>
                        <li><strong>Usage Data:</strong> Information on how you use our website, including pages visited, time spent on the website, and technical data (IP address, browser type, and device information).</li>
                        <li><strong>Course Progress and Performance Data:</strong> Information about your participation in our courses, including assignments, quizzes, and other learning activities.</li>
                    </ul>
                    <br></br>
                    <br></br>

                    <h3>2. How We Use Your Information</h3>
                    <p>We use the personal information we collect for the following purposes:</p>
                    <ul>
                        <li><strong>Providing Services:</strong> To deliver and manage the courses you enroll in and to process your payments.</li>
                        <li><strong>Improving User Experience:</strong> To personalize your experience and tailor content to your interests.</li>
                        <li><strong>Communication:</strong> To send you updates about our services, special offers, and important information regarding your account or participation in our courses.</li>
                        <li><strong>Analytics and Research:</strong> To improve our courses, website, and services based on usage patterns and feedback.</li>
                        <li><strong>Compliance:</strong> To comply with legal obligations and prevent fraud.</li>
                    </ul>
                    <br></br>

                    <h3>3. Data Sharing and Disclosure</h3>
                    <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following situations:</p>
                    <ul>
                        <li><strong>Service Providers:</strong> We may share your information with trusted third-party vendors and service providers who assist us in operating our website, processing payments, or delivering course materials.</li>
                        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to valid legal requests.</li>
                        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of PaperLive, your information may be transferred as part of the transaction.</li>
                    </ul>
                    <br></br>

                    <h3>4. Data Security</h3>
                    <p>We take the security of your personal information seriously and employ appropriate technical and organizational measures to protect it from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                    <br></br>

                    <h3>5. Your Rights and Choices</h3>
                    <p>You have the following rights regarding your personal information:</p>
                    <ul>
                        <li><strong>Access and Correction:</strong> You can access and update your personal information at any time by logging into your account or contacting us.</li>
                        <li><strong>Data Deletion:</strong> You can request the deletion of your personal information by contacting us, subject to any legal obligations we may have to retain certain data.</li>
                        <li><strong>Marketing Preferences:</strong> You can opt out of receiving marketing communications from us by following the unsubscribe instructions in our emails or contacting us directly.</li>
                    </ul>
                    <br></br>

                    <h3>6. Cookies and Tracking Technologies</h3>
                    <p>We use cookies and similar tracking technologies to collect information about your interactions with our website. This helps us provide a better user experience, analyze site traffic, and show relevant advertisements. You can control cookie preferences through your browser settings.</p>
                    <br></br>

                    <h3>7. Third-Party Links</h3>
                    <p>Our website may contain links to third-party websites or services that are not operated by us. We are not responsible for the privacy practices of these third-party sites, and we encourage you to review their privacy policies.</p>
                    <br></br>

                    <h3>8. Children's Privacy</h3>
                    <p>PaperLive's services are not intended for children under the age of 13. We do not knowingly collect personal information from children. If you believe we have unintentionally collected such information, please contact us to have it removed.</p>
                    <br></br>

                    <h3>9. Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on this page, and we encourage you to review the policy periodically.</p>
                    <br></br>

                    <h3>10. Contact Us</h3>
                    <p>If you have any questions or concerns about this Privacy Policy or the way we handle your personal information, please contact us at:</p>
                    <ul>
                        <li>Email: <Link href="mailto:support@paperlive.in">support@paperlive.in</Link></li>
                        <li>Phone: +91 9430390172</li>
                        <li>Address: ADAZPAPERLIVE LEARNING LLP, Shop no 03, No 32, 1st Ward, Dommasandra, Sarjapura Road, Anekal, Bengaluru - 562125, Karnataka</li>
                    </ul>
                </div>

            </div>
        </React.Fragment>
    );
}

export default Privacy;

import { Button, Card, TextField } from '@mui/material';
import * as React from 'react';

export default function ScholarReact(props) {
    const { isSmallScreen } = props;

    const [formError, setFormError] = React.useState('');
    const [formValues, setFormValues] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        website: window.location.href,
    });
    const [errors, setErrors] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });

    const validateFirstName = (value) => (!value ? 'First name is required' : '');
    const validateLastName = (value) => (!value ? 'Last name is required' : '');
    const validateEmail = (value) => {
        if (!value) return 'Email is required';
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !emailRegex.test(value) ? 'Invalid email address' : '';
    };
    const validatePhone = (value) => {
        if (!value) return 'Phone number is required';
        const phoneRegex = /^\d{10}$/;
        return !phoneRegex.test(value) ? 'Phone number must be 10 digits' : '';
    };

    const handleChange = (e, event) => {
        const { value } = e.target;
        setFormValues({ ...formValues, [event]: value });

        let error = '';
        switch (event) {
            case 'firstName':
                error = validateFirstName(value);
                break;
            case 'lastName':
                error = validateLastName(value);
                break;
            case 'email':
                error = validateEmail(value);
                break;
            case 'phone':
                error = validatePhone(value);
                break;
            default:
                break;
        }

        setErrors({ ...errors, [event]: error });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const firstNameError = validateFirstName(formValues.firstName);
        const lastNameError = validateLastName(formValues.lastName);
        const emailError = validateEmail(formValues.email);
        const phoneError = validatePhone(formValues.phone);

        if (firstNameError || lastNameError || emailError || phoneError) {
            setErrors({
                firstName: firstNameError,
                lastName: lastNameError,
                email: emailError,
                phone: phoneError,
            });

            setFormError(
                firstNameError || lastNameError || emailError || phoneError
            );
            return;
        }

        document.forms['WebToLeads774394000000417076'].submit();
    };

    return (
        // <Card>
        //     <div style={{
        //         margin: "auto",
        //         padding: "0.5rem",
        //         fontSize: isSmallScreen ? "1.5rem" : "2rem",
        //         fontWeight: "600",
        //         color: "white",
        //         height: isSmallScreen ? "40px" : "50px",
        //         backgroundImage: "linear-gradient(90deg, #0075c9, #8f128e)"
        //     }}
        //     >
        //         Looking for a discount code?
        //     </div>
        <div style={{ margin: "1rem" }}>
            <form
                id="webform774394000000417076"
                action="https://crm.zoho.in/crm/WebToLeadForm"
                name="WebToLeads774394000000417076"
                method="POST"
                onSubmit={handleFormSubmit}
                acceptCharset="UTF-8"
            >
                <input
                    type="text"
                    style={{ display: "none" }}
                    name="xnQsjsdp"
                    defaultValue="964543106c3ae7afbb5539a4799fc9369e01e4f472a15def1544213fb2881d33"
                />
                <input type="hidden" name="zc_gad" id="zc_gad" defaultValue="" />
                <input
                    type="text"
                    style={{ display: "none" }}
                    name="xmIwtLD"
                    defaultValue="f51255974d13c7cf0e8e2bb0c5b883275629b31a45c7e2fffce67bae029b924214ce92ad42a0628f795719b65290f376"
                />
                <input
                    type="text"
                    style={{ display: "none" }}
                    name="actionType"
                    defaultValue="TGVhZHM="
                />
                <input
                    type="text"
                    style={{ display: "none" }}
                    name="returnURL"
                    defaultValue="https://paperlive.in/thankyou"
                />
                <TextField
                    value={formValues.firstName}
                    onChange={(e) => handleChange(e, "firstName")}
                    id="First_Name"
                    name="First Name"
                    size="small"
                    label="First Name"
                    variant="outlined"
                    style={{ width: "48%", marginBottom: "1rem" }}
                    error={!!errors.firstName}
                />
                <TextField
                    value={formValues.lastName}
                    onChange={(e) => handleChange(e, "lastName")}
                    id="Last_Name"
                    name="Last Name"
                    size="small"
                    label="Last Name"
                    variant="outlined"
                    style={{ width: "48%", marginBottom: "1rem", float: "right" }}
                    error={!!errors.lastName}
                />
                <TextField
                    value={formValues.email}
                    onChange={(e) => handleChange(e, "email")}
                    id="Email"
                    name="Email"
                    size="small"
                    label="Email"
                    variant="outlined"
                    style={{ width: "100%", marginBottom: "1rem" }}
                    error={!!errors.email}
                />
                <TextField
                    value={formValues.phone}
                    onChange={(e) => handleChange(e, "phone")}
                    id="Phone"
                    name="Phone"
                    size="small"
                    label="Phone"
                    variant="outlined"
                    style={{ width: "100%", marginBottom: "1rem" }}
                    error={!!errors.phone}
                />
                <TextField
                    value={formValues.website}
                    id="Website"
                    name="Website"
                    size="small"
                    label="Website"
                    variant="outlined"
                    style={{ width: "48%", marginBottom: "1rem", display: "none" }}
                />
                <div style={{ color: "red" }}>{formError}</div>
                <div style={{ width: "100%" }}>
                    <Button
                        variant="contained"
                        style={{
                            color: "white",
                            borderRadius: "25px",
                            backgroundColor: "#8f128e",
                            display: "block",
                            margin: "auto",
                        }}
                        type="submit"
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </div>
        // </Card>
    );
}
